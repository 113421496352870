export const featuresData=[
  {
    feature_id:1,
    title:'Experienced lecturers',
    des:'We Have Qualified Lecturers In Our E-Learning System',
    img:require("../../../assets/experts.png"),
    direction:'',
  },
  {
    feature_id:2,
    title:'Online Courses',
    des:'Here You Will Get All Courses You Need To Be qualified Doctor',
    direction:'',
    img:require("../../../assets/video.png"),
  },
  {
    feature_id:3,
    title:'Best System',
    des:'We Are The Best In Our medical Field  all over the world',
    direction:'',
    img:require("../../../assets/best.png"),
  },
]
