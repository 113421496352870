import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { locationIcon } from "./svg";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        <center
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
            padding: "5px 10px",
            width: "fit-content",
            margin: "auto",
            borderBottom: "2.2px solid var(--main-color)",
          }}
        >
          This Site is Owned by Dr. El Matary LLC
        </center>
        <div className="footer-content pt-5 pb-5">
          <div className="row divAlignCenter">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-text">
                  <img
                    className="footerImage"
                    width={190}
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1707141565/Logo_set-06_t8patg.png"
                    alt=""
                  />
                  <p>
                  Stay updated with the latest insights, updates, and exclusive content delivered straight to your 
                  inbox. Sign up now and never miss out!

                  </p>
                </div>
                {/* <p style={{ color: "white", display: "flex", gap: "8px" }}>
                  {locationIcon}{" "}
                  <span>2201 MENAUL BLVD NE, STE A, ALBUQUERQUE, NM 87107</span>
                </p> */}
                {/* <p style={{ color: "white" }}>01008906010</p> */}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  {/* <h3>Useful Links</h3> */}
                </div>
                <ul>
                  {/* <li>
                    <Link to={"/"}>Resources</Link>
                  </li> */}

                  <li>
                    <Link to="/About">About</Link>
                  </li>

                  {/* <li>
                    <Link to="/allcourses">FAQ</Link>
                  </li> */}

                  <li>
                    <Link to="/techsup">Help center</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/allcourses">Courses</Link>
                  </li>

                  {/* <li>
                    <Link to="/allcourses">Surgery</Link>
                  </li>

                  <li>
                    <Link to="/allcourses">Anatomy</Link>
                  </li>

                  <li>
                    <Link to="/allcourses">Operative</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Join Our Newsletter</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>
                    Get the latest updates, insights, and exclusive content
                    delivered straight to your inbox. Join now and never miss
                    out!
                  </p>
                  <form class="center">
                    <button type="button">Subscribe</button>
                    <input placeholder="Enter Your Email Adress"></input>
                  </form>
                  <p>We Only Send Interesting And Relevant Emails.</p>
                </div>
                <div className="subscribe-form">
                  <form action="#">
                    <button>
                      <img src={require("../../assets/paper.jpeg")} alt="" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <center className="text-center text-lg-left">
            <div className="copyright-text">
              <p>&copy; 2024 Dr Elmatary LLC. All Rights Reserved</p>
            </div>
          </center>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
