import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 96% !important;
  margin: auto;
  font-family: 'Arial', sans-serif;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isRecording ? '#ff4d4d' : '#4caf50')};
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  width: 150px;
  margin-top: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${(props) => (props.isRecording ? '#ff6666' : '#66bb6a')};
  }

  svg {
    margin-right: 10px;
    fill: white;
  }
`;

const AudioPlayerContainer = styled.div`
  position: relative;
  margin-top: 20px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: repeating-linear-gradient(
      45deg,
      #000,
      #000 5px,
      #fff 5px,
      #fff 10px
    );
    z-index: -1;
  }
`;

const AudioPlayer = styled.audio`
  width: 100%;
  outline: none;
  background: #f9f9f9;
  padding: 10px;
`;

const ActionButton = styled(Button)`
  background-color: ${(props) => props.color};
  margin-bottom: 0; // Remove the bottom margin for row alignment

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
`;

const VoiceRecorder = (data) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [voiceName, setVoiceName] = useState('');
  const [recordings, setRecordings] = useState(data?.data?.atts || []); // State for recordings
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  // useEffect(() => {
  //   if (data?.data?.atts) {
  //     setRecordings(data?.data?.atts);
  //   }
  // }, [data?.data?.atts]);
  useEffect(() => {
    data?.data?.setUserAttachmentForCurrentPage(
      "voice",
      data?.data?.page_number,
      setRecordings,
      data?.data?.setVoice
    );
  }, [data?.data?.voice]);
  const startRecording = async () => {
    setIsRecording(true);
    audioChunksRef.current = [];

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/mp3',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioBlob(audioBlob);
        setAudioURL(audioUrl);
      };

      mediaRecorderRef.current.start();
    } else {
      alert('getUserMedia is not supported in this browser');
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };

  const handleRecordingClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleRemoveAudio = () => {
    setAudioURL('');
    setAudioBlob(null);
    audioChunksRef.current = [];
  };

  const handleUploadAudio = async () => {
    if (!audioBlob) return;

    const formData = new FormData();
    formData.append('voice', audioBlob);
    formData.append('voice_name', voiceName || 'recording.mp3');

    try {
      const response = await fetch(
        'https://dr-elmatary.com/Matary_site/upload_notes.php',
        {
          method: 'POST',
          body: formData,
        }
      );

      const result = await response.json();
      if (response.ok && result.status == 'success') {
        const insertAttResponse = await fetch(
          'https://dr-elmatary.com/Matary_site/user/books/insert_att.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              student_id: data?.data?.student_id,
              token_value: data?.data?.token_value,
              book_id: data?.data?.book_id,
              page_number: data?.data?.page_number,
              voice_url: result.message,
              voice_name: voiceName || "Recording",
            }),
          }
        );

        const insertResult = await insertAttResponse.json();

        if (insertResult?.status == "success") {
          toast.success('Voice inserted successfully!');
          // Update the list of recordings
          setRecordings([
            ...recordings,
            {
              att_id: insertResult.att_id,
              voice_url: result.message,
              voice_name: voiceName || "Recording",
            },
          ]);
          data?.data?.getData();
        } else {
          toast.error('Failed to insert voice.');
        }
      } else {
        alert('Failed to upload audio.');
      }
    } catch (error) {
      alert('Error uploading audio.');
      console.error('Error:', error);
    }
  };

  const handleDeleteAudio = async (attId) => {
    try {
      const response = await fetch(
        'https://dr-elmatary.com/Matary_site/user/books/delete_att.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            student_id: data?.data?.student_id,
            token_value: data?.data?.token_value,
            att_id: attId,
          }),
        }
      );

      const result = await response.json();

      if (result?.status == "success") {
        toast.success('Voice deleted successfully!');
        // Update the list of recordings after deletion
        setRecordings(
          recordings.filter((recording) => recording.att_id !== attId)
        );
        data?.data?.getData();
      } else {
        toast.error('Failed to delete voice.');
      }
    } catch (error) {
      toast.error('Error deleting voice.');
      console.error('Error:', error);
    }
  };

  return (
    <Container>
      <Button onClick={handleRecordingClick} isRecording={isRecording}>
        {isRecording ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M18 6l-12 12h12z" />
            </svg>
            Stop
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 2a3.5 3.5 0 013.5 3.5V11a3.5 3.5 0 01-7 0V5.5A3.5 3.5 0 0112 2zm-1.5 10h3a1.5 1.5 0 001.5-1.5V5.5a1.5 1.5 0 00-3 0v5.5H11v-5.5a1.5 1.5 0 10-3 0V11c0 .818.548 1.5 1.5 1.5zm6 1.5v.5A6.5 6.5 0 0112 20.5a6.5 6.5 0 01-6.5-6.5v-.5h-1v.5a7.5 7.5 0 007.5 7.5 7.5 7.5 0 007.5-7.5v-.5h-1z" />
            </svg>
            Record
          </>
        )}
      </Button>
      <Input
        type="text"
        value={voiceName}
        onChange={(e) => setVoiceName(e.target.value)}
        placeholder="Enter voice name"
      />
      {audioURL && (
        <>
          <div style={{ width: '100%' }}>
            <AudioPlayerContainer>
              <AudioPlayer controls src={audioURL} />
            </AudioPlayerContainer>
            <ActionsRow>
              <ActionButton
                onClick={handleRemoveAudio}
                color="#f44336"
                hoverColor="#e57373"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18v2H3V6zm3 4h12v12H6V10zm4-8h4v2h-4V2z" />
                </svg>
                Remove
              </ActionButton>
              <ActionButton
                onClick={handleUploadAudio}
                color="#2196f3"
                hoverColor="#64b5f6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.12 8.29l-3.3 3.3L10 12.7l4.59-4.59L19 11v9H5v-9l4.41-4.41 1.42 1.42zM5 5V3h14v2H5z" />
                </svg>
                Upload
              </ActionButton>
            </ActionsRow>
          </div>
        </>
      )}
      <div className="columnDiv" style={{ width: '100%' }}>
        {recordings.length > 0
          ? recordings.map((item) => (
              <div
                className="columnDivAtt"
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  borderBottom: '.1px solid',
                  padding: '10px 0',
                }}
                key={item.att_id}
              >
                <h2
                  style={{
                    color: 'var(--main-color)',
                    width: '100%',
                    textAlign: 'start',
                  }}
                >
                  {item.voice_name}
                </h2>
                <audio src={item.voice_url} controls></audio>
                <ActionButton
                  onClick={() => handleDeleteAudio(item.att_id)}
                  color="#f44336"
                  hoverColor="#e57373"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3 6h18v2H3V6zm3 4h12v12H6V10zm4-8h4v2h-4V2z" />
                  </svg>
                  Remove
                </ActionButton>
              </div>
            ))
          : null}
      </div>
    </Container>
  );
};

export default VoiceRecorder;
