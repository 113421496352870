export default function Skills() {
  return (
    <section className="px-10 my-8 text-[#597B82]">
      <h2 className="text-lg font-normal my-4">Skills you’ll gain</h2>
      <div className="flex align-middle gap-2 flex-wrap">
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Surgical Technique Mastery</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Anatomical Knowledge Application</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Diagnostic Skills</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Patient Management</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Infection Control</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Teamwork and Communication</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Critical Thinking and Problem-Solving</p>
        </div>
        <div className="bg-[#E1E1E1] px-6 py-1 rounded-lg flex justify-center align-middle">
          <p className="text-[11px]">Ethical and Legal Considerations</p>
        </div>
      </div>
    </section>
  );
}
