export const coursesData=[
  {
    course_id:1,
    title:'General Surgery 2022',
    doctore:'Dr.Mohamed ElMatary',
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    hour:'205H',
    img:require("../../../assets/cou1.jpg"),
    min:'30M',
    type_id:2,
    skil_lev:'High Level',
    fav:'0',
    duration:'15H 22M',
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    lecatures:12,
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
  {
    course_id:2,
    title:'GIT 2022',
    doctore:'Dr.Mohamed ElMatary',
    img:require("../../../assets/cour2.jpg"),
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    hour:'205H',
    lecatures:12,
    duration:'15H 22M',
    min:'30M',
    skil_lev:'High Level',
    type_id:1,
    fav:'1',
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
  {
    course_id:3,
    title:'Special Surgery 2022',
    doctore:'Dr.Mohamed ElMatary',
    img:require("../../../assets/cour3.jpg"),
    lecatures:12,
    duration:'15H 22M',
    hour:'205H',
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    type_id:3,
    skil_lev:'High Level',
    min:'30M',
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    fav:'1',
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
  {
    course_id:1,
    title:'General Surgery 2022',
    doctore:'Dr.Mohamed ElMatary',
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    hour:'205H',
    img:require("../../../assets/cou1.jpg"),
    skil_lev:'High Level',
    lecatures:12,
    min:'30M',
    duration:'15H 22M',
    type_id:4,
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    fav:'0',
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
  {
    course_id:2,
    title:'GIT 2022',
    doctore:'Dr.Mohamed ElMatary',
    img:require("../../../assets/cour2.jpg"),
    hour:'205H',
    skil_lev:'High Level',
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    duration:'15H 22M',
    lecatures:12,
    type_id:5,
    min:'30M',
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    fav:'0',
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
  {
    course_id:3,
    title:'Special Surgery 2022',
    doctore:'Dr.Mohamed ElMatary',
    img:require("../../../assets/cour3.jpg"),
    skil_lev:'High Level',
    lecatures:12,
    duration:'15H 22M',
    hour:'205H',
    type_id:6,
    description:'This is The Description Of My Course In this System and we Will Explain All This in This Course',
    min:'30M',
    fav:'0',
    meta_tags:[
      {
        id:0,
        name:'Nephrology1',
      },
      {
        id:0,
        name:'Nephrology2',
      },
    ],
    features:[
      {
        id:1,
        name:'Nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      },
      {
        id:2,
        name:'nephrology',
        content:[
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
          {
            id:0,
            name:'Chronic Renal Failure 1',
            time:'01:26:40',
          },
          {
            id:1,
            name:'Chronic Renal Failure 2',
            time:'01:26:40',
          },
          {
            id:2,
            name:'Acute renal failure',
            time:'00:55:33',
          },
          {
            id:3,
            name:'Chronic & Acute renal failure MCQ ',
            time:'01:34:35',
          },
          {
            id:4,
            name:'Glomerulonephritis ',
            time:'01:25:15',
          },
        ]
      }

    ]
  },
]

export const coursesTypesData=[
  {
    id:0,
    name:'all',
    selected:true,
  },
  {
    id:1,
    name:'Internal Medicine',
    selected:false,
  },
  {
    id:2,
    name:'Crash Courses',
    selected:false,
  },
  {
    id:3,
    name:'Surger Incapsule',
    selected:false,
  },
  {
    id:4,
    name:'surgery',
    selected:false,
  },
  {
    id:5,
    name:'Basic Science',
    selected:false,
  },
  {
    id:6,
    name:'Operative',
    selected:false,
  },
  {
    id:7,
    name:'Revision',
    selected:false,
  },
]
