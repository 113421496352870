// About.jsx
import React, { useEffect, useState } from "react";
import "./About.css";

/* SVG Icons as React Components */
const BookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#eb456a"
    viewBox="0 0 24 24"
  >
    <path d="M19 2h-14c-1.1 0-2 .9-2 2v16l4-4h12c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z" />
  </svg>
);

const GraduationCapIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#eb456a"
    viewBox="0 0 24 24"
  >
    <path d="M12 2l-10 6v12h20v-12l-10-6zm0 7.3c-1.38 0-2.5-1.12-2.5-2.5S10.62 4.3 12 4.3s2.5 1.12 2.5 2.5S13.38 9.3 12 9.3z" />
  </svg>
);

const TeacherIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#eb456a"
    viewBox="0 0 24 24"
  >
    <path d="M12 12c2.7 0 5-2.3 5-5s-2.3-5-5-5-5 2.3-5 5 2.3 5 5 5zm0 2c-3.3 0-10 1.7-10 5v3h20v-3c0-3.3-6.7-5-10-5z" />
  </svg>
);

const LightbulbIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#eb456a"
    viewBox="0 0 24 24"
  >
    <path d="M9 21h6v-1h-6v1zm3-20c-4.97 0-9 4.03-9 9 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
  </svg>
);

const UsersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#eb456a"
    viewBox="0 0 24 24"
  >
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S10.62 5 9 5s-3 1.34-3 3 1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5z" />
  </svg>
);

const BuildingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#fff"
    viewBox="0 0 24 24"
  >
    <path d="M4 4h4v16h-4v-16zm6 6h4v10h-4v-10zm6-4h4v14h-4v-14z" />
  </svg>
);

const MapMarkerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#fff"
    viewBox="0 0 24 24"
  >
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
  </svg>
);

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="#fff"
    viewBox="0 0 24 24"
  >
    <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1 1 0 011.11-.21c1.21.48 2.53.73 3.88.73a1 1 0 011 1V20a1 1 0 01-1 1C10.76 21 3 13.24 3 4a1 1 0 011-1h3.5a1 1 0 011 1c0 1.35.25 2.67.73 3.88a1 1 0 01-.21 1.11l-2.2 2.2z" />
  </svg>
);

/* Team Member Data */
const teamMembers = [
  {
    name: "Dr. Jane Smith",
    role: "Lead Educator",
    bio: "Dr. Smith specializes in cardiovascular medicine and brings over 15 years of teaching experience.",
    image: "/images/team1.jpg",
  },
  {
    name: "Dr. John Doe",
    role: "Course Developer",
    bio: "With a passion for medical education, Dr. Doe develops comprehensive course materials for our platform.",
    image: "/images/team2.jpg",
  },
  {
    name: "Dr. Emily Davis",
    role: "Academic Advisor",
    bio: "Dr. Davis provides personalized academic guidance to help students achieve their career aspirations.",
    image: "/images/team3.jpg",
  },
  // Add more team members as needed
];

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /* Carousel State */
  const [currentSlide, setCurrentSlide] = useState(0);

  const totalSlides = teamMembers.length;

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  /* Automatic Slide */
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="about__hero">
        <div className="about__hero-content">
          <h1 className="about__hero-title">Empowering Medical Professionals</h1>
          <p className="about__hero-subtitle">
            At Dr. El Matary's Online Training, we provide comprehensive resources to elevate your medical career.
          </p>
          <button className="about__hero-button" onClick={() => window.location.href = "/login"}>Join Us Today</button>
        </div>
      </section>

      {/* Main Container */}
      <div className="about__container">
        {/* Our Story Section */}
        <section className="about__story">
          <img
            src={require("../../assets/EL-MAT.png")}

            alt="Our Story"
            className="about__story-image"
          />
          <div className="about__story-content">
            <h2 className="about__story-title">Our Story</h2>
            <p className="about__story-text">
              Founded by Dr. El Matary, our platform was created to bridge the gap in medical education by offering flexible and engaging learning solutions. With a commitment to excellence, we have empowered countless students and professionals to achieve their academic and career goals through high-quality content and personalized support.
            </p>
          </div>
        </section>

        {/* What We Offer Section */}
        <section className="about__offer">
          <h2 className="about__offer-title">What We Offer</h2>
          <div className="about__offer-grid">
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <BookIcon />
              </div>
              <h3 className="about__offer-name">Undergraduate Programs</h3>
              <p className="about__offer-description">
                Comprehensive courses designed to build a strong foundation in medical sciences.
              </p>
            </div>
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <GraduationCapIcon />
              </div>
              <h3 className="about__offer-name">MRCS Exam Preparation</h3>
              <p className="about__offer-description">
                Specialized training programs tailored to help you excel in MRCS examinations.
              </p>
            </div>
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <TeacherIcon />
              </div>
              <h3 className="about__offer-name">Live Lectures</h3>
              <p className="about__offer-description">
                Interactive live sessions led by industry experts to enhance your learning experience.
              </p>
            </div>
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <LightbulbIcon />
              </div>
              <h3 className="about__offer-name">Personalized Learning</h3>
              <p className="about__offer-description">
                Customized learning plans to cater to your unique educational needs and goals.
              </p>
            </div>
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <UsersIcon />
              </div>
              <h3 className="about__offer-name">Community Support</h3>
              <p className="about__offer-description">
                Join a vibrant community of learners and professionals to collaborate and grow together.
              </p>
            </div>
            <div className="about__offer-card">
              <div className="about__offer-icon">
                <BookIcon />
              </div>
              <h3 className="about__offer-name">Extensive Question Banks</h3>
              <p className="about__offer-description">
                Access a vast repository of practice questions to test and enhance your knowledge.
              </p>
            </div>
          </div>
        </section>



        {/* Contact Information Section */}
        <section className="about__contact">
          <h2 className="about__contact-title">Get in Touch</h2>
          <div className="about__contact-grid">
            <div className="about__contact-card">
              <div className="about__contact-icon">
                <BuildingIcon />
              </div>
              <div>
                <h3 className="about__contact-info-title">Company Name</h3>
                <p className="about__contact-info-detail">DR.Elmatary LLC</p>
              </div>
            </div>
            {/* <div className="about__contact-card">
              <div className="about__contact-icon">
                <MapMarkerIcon />
              </div>
              <div>
                <h3 className="about__contact-info-title">US Address</h3>
                <p className="about__contact-info-detail">
                  2201 MENAUL BLVD NE, STE A, ALBUQUERQUE, NM 87107
                </p>
              </div>
            </div> */}
            <div className="about__contact-card">
              <div className="about__contact-icon">
                <PhoneIcon />
              </div>
              <div>
                <h3 className="about__contact-info-title">US Phone Number</h3>
                <p className="about__contact-info-detail">+1 (872) 308-0246</p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <footer className="about__footer">
        <p className="about__footer-text">
          &copy; {new Date().getFullYear()} DR.Elmatary LLC. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default About;
