import { useEffect, useRef } from 'react';
import { loadPDF } from './helperFunctions';
import { PDFButtons } from './PDFBUTTONS';
import { toast } from 'react-toastify';

function PDFViewer(props) {
  const containerRef = useRef(null);
  const instanceRef = useRef(null);

  useEffect(() => {
    let PSPDFKit;
    const container = containerRef.current;
    (async function () {
      PSPDFKit = await import('pspdfkit');

      PSPDFKit.unload(container);

      const instance = await loadPDF({
        PSPDFKit,
        container,
        document: props.document,
      });
      instance.setToolbarItems([
        ...PDFButtons,
        {
          type: 'custom',
          id: 'myCustomButton',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a4 4 0 0 0 4-4V7.414a1 1 0 0 0-.293-.707l-3.414-3.414A1 1 0 0 0 16.586 3H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4"/><path d="M9 3h6v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm8 18v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7"/><path stroke-linecap="round" d="M11 17h2"/></g></svg>',
          onPress: () => {
            handleSave();
          },
        },
        {
          type: 'custom',
          id: 'fullScreenButton',
          title: "Full Screen",
          onPress: () => {
            !document.getElementById("pspdfkitContainer").fullscreenElement
              ? document
                  .getElementById("pspdfkitContainer")
                  ?.requestFullscreen()
              : document.getElementById("pspdfkitContainer")?.exitFullscreen();
          },
        },
      ]);

      instanceRef.current = instance;
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document]);

  const handleSave = async () => {
    if (instanceRef.current) {
      const buffer = await instanceRef.current.exportPDF();
      const blob = new Blob([buffer], { type: 'application/pdf' });
      const myHeaders = new Headers();

      const formdata = new FormData();
      formdata.append("voice", blob);
      formdata.append("voice_name", "Mohammeed");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://dr-elmatary.com/Matary_site/upload_notes.php",
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.status) {
            toast.success(result.message);
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div
      style={{ width: '100%', height: '100vh', position: 'relative' }}
      id="pspdfkitContainer"
    >
      <div ref={containerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}
export default PDFViewer;
